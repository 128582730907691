@media screen {
	.print {
		display: none !important;
	}
}

@media print {
	.print {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index:999999 !important;
		-webkit-print-color-adjust: exact !important;
		print-color-adjust: exact !important;
		background: #ffffff;
		overflow: hidden !important;
		@page {
			size: A4 landscape !important;
		}
	}

	.layout-main-content {
		overflow: visible !important;
	}

	.layout-topbar {
		display: none !important;
	}

	header,
	footer,
	thead,
	tfoot {
		display: none !important;
	}

	::-webkit-scrollbar {
		display: none !important;
	}

	.item {
		break-inside: avoid-column !important;
	}

	.item:nth-of-type(2) {
		break-inside: avoid-column !important;
	}
}
